// comment to trigger build
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Banner,
  VariableContent,
  Image,
  SVGImage,
  List,
  ListItem,
  LeshenPhoneCTA,
  PackageCard,
  Brandy,
  Price,
  SplitContent,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Columns, Column, Typography } from '@leshen/ui'
import { graphql, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const SpanishStateTemplate = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <SplitContent
            backgroundColor="dark"
            alignImageToBottom
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                alt={data.heroImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">Frontier&reg; Internet</Typography>
                <Typography variant="h4">en {State_Name}</Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.heroPrice?.price}
                  bullets={data?.heroPrice?.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography>
                      Enrutador Wi-Fi Amazon eero incluido<sup>**</sup>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Sin límites de datos ni cargos por exceso
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Precios de mes a mes sin compromiso anual
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LeshenPhoneCTA variant="hero" color="primary">
                  LLAMA AL
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Compra planes de Internet de Frontier disponibles en{' '}
                  {State_Name}
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard label={false} packageData={data.package1} />

              <PackageCard label={false} packageData={data.package2} />

              <PackageCard label={false} packageData={data.package3} />
            </Columns>
          </VariableContent>
          <Banner
            backgroundColor="primary"
            layout="100"
            centerAligned="true"
            mainContent={
              <>
                <Typography variant="h4">
                  Consulta la disponibilidad por ciudad
                </Typography>
                <Typography variant="h5">
                  Encuentra las mejores ofertas de Internet en tu zona: Por
                  favor elige tu ciudad de la lista
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Seleccionar Ciudad"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Refuerza los servicios de tu hogar con Frontier Internet en{' '}
                  {State_Name}
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <Column
                backgroundColor=""
                borderColor="light"
                borderType="stroke"
                image={
                  <SVGImage
                    svg={data.noContractIcon.cloudinary[0].svg.code}
                    presentationWidth={
                      data.noContractIcon.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
                svg={false}
              >
                <Typography variant="h4">Sin compromisos anuales</Typography>
                <Typography variant="">
                  Los planes de fibra ni siquiera requieren un contrato anual.
                </Typography>
              </Column>
              <Column
                backgroundColor=""
                borderColor="light"
                borderType="stroke"
                image={
                  <SVGImage
                    svg={data.routerIcon.cloudinary[0].svg.code}
                    presentationWidth={
                      data.routerIcon.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
                svg={false}
              >
                <Typography variant="h4">Conexión inalámbrica</Typography>
                <Typography variant="">
                  Experimenta la libertad de conectarte de forma inalámbrica en
                  tu hogar con un router Wi-Fi eero de Amazon incluido.
                  <sup>**</sup>
                </Typography>
              </Column>
              <Column
                backgroundColor=""
                borderColor="light"
                borderType="stroke"
                image={
                  <SVGImage
                    svg={data.speedometerIcon.cloudinary[0].svg.code}
                    presentationWidth={
                      data.speedometerIcon.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
                svg={false}
              >
                <Typography variant="h4">Sin límites de datos</Typography>
                <Typography variant="">
                  Para una diversión en línea sin fin.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <VariableContent
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h2">
                  Aumenta tu señal Wi-Fi desde $10/mes<sup>♦</sup>
                </Typography>
                <Typography>
                  Elimina los puntos muertos agregando extensores Wi-Fi para
                  todo el hogar a tu plan.
                </Typography>
                <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
              </>
            }
            alignMainContent="Left"
          />
          <SplitContent
            image={
              <Image
                data={data.homePhoneImage.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.homePhoneImageMobile.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImageMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Ahorra en grande con los paquetes de Frontier en {State_Name}
                </Typography>
                <Typography>
                  Obtén todos los servicios para el hogar que necesitas con un
                  plan combinado de Frontier. Cuando elijas el teléfono e
                  Internet de Frontier en {State_Name}, obtendrás ambos
                  servicios en una sola factura por un precio bajo. Estos son
                  algunos de los beneficios que te esperan con un paquete
                  combinado de Frontier:
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Llamadas nacionales ilimitadas</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Datos mensuales ilimitados</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Soporte técnico gratuito 24/7</Typography>
                  </ListItem>
                </List>
                <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            backgroundColor=""
            mainContent={
              <>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerTwo.symbol}
                  text={data.disclaimerTwo.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerThree.symbol}
                  text={data.disclaimerThree.text}
                  variant="legal"
                />
              </>
            }
            alignMainContent="Left"
          />
        </>
      }
    />
  )
}

SpanishStateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default SpanishStateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query SpanishStateTemplateQuery(
    $pagePath: String!
    $stateAbbreviation: String!
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerFrontierbundlesAoaSpanish(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "cd610f2a-c91d-5823-9488-fbca32d2101c" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "0f20b283-aadf-507e-bc15-5aef12af97d7" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerFrontierbundlesAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    noContractIcon: contentfulMedia(
      contentful_id: { eq: "UOmJklixDyTIWZJr0SfSv" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    routerIcon: contentfulMedia(
      contentful_id: { eq: "1xbtm0bhAi89Njc98kXFdH" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    speedometerIcon: contentfulMedia(
      contentful_id: { eq: "7AvooAr2FSy6ebTFPqEpbK" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    homePhoneImage: contentfulMedia(
      contentful_id: { eq: "4USDaC0rxHAy1lsc4iEkq0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "3jjN46RLszRN4O1SgIagy9" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    homePhoneImageMobile: contentfulMedia(
      contentful_id: { eq: "4USDaC0rxHAy1lsc4iEkq0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroPrice: brandyPackage(brandy_id: { eq: "ftr-sigil-internet-hero-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1: brandyPackage(brandy_id: { eq: "ftr-sigil-internet-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "ftr-sigil-homephone-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "ftr-sigil-frontiersecure-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-copperfooter-1-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-wholehomewifi-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-eeroo-es" }
    ) {
      id
      text
      symbol
    }
  }
`
